// Text Color:
$brand: #35080e;
$main-text: #333333;
$lighter-text: rgba(0, 0, 0, 0.4);

// Main Part of the Site:
$site-background: #f8f8f8;
$site-border: #999999;

// Components style
$main-background: #F5F5F5;
$parts-background: #F8F8F8;
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

$yellow-border: #ffcc00;
$shadow-effect: rgba(0, 0, 0, 0.2);